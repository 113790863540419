import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";

export const useFeedbackFormOptions = () => {
  const PLEASE_SELECT_OPTION = i18n._(t({ message: "Please select" }));

  // Experience
  const EXPERIENCE_OPTION_1 = i18n._(t({ message: "1. Very dissatisfied" }));
  const EXPERIENCE_OPTION_2 = i18n._(
    t({ message: "2. Somewhat dissatisfied" })
  );
  const EXPERIENCE_OPTION_3 = i18n._(
    t({ message: "3. Neither satisfied or dissatisfied" })
  );
  const EXPERIENCE_OPTION_4 = i18n._(t({ message: "4. Somewhat satisfied" }));
  const EXPERIENCE_OPTION_5 = i18n._(t({ message: "5. Very satisfied" }));

  const EXPERIENCE_OPTIONS = [
    { key: PLEASE_SELECT_OPTION, value: "" },
    { key: EXPERIENCE_OPTION_1, value: "1" },
    { key: EXPERIENCE_OPTION_2, value: "2" },
    { key: EXPERIENCE_OPTION_3, value: "3" },
    { key: EXPERIENCE_OPTION_4, value: "4" },
    { key: EXPERIENCE_OPTION_5, value: "5" },
  ];

  // Importance
  const IMPORTANCE_OPTION_1 = i18n._(t({ message: "1. Not at all important" }));
  const IMPORTANCE_OPTION_2 = i18n._(t({ message: "2. Not so important" }));
  const IMPORTANCE_OPTION_3 = i18n._(t({ message: "3. Somewhat important" }));
  const IMPORTANCE_OPTION_4 = i18n._(t({ message: "4. Important" }));
  const IMPORTANCE_OPTION_5 = i18n._(t({ message: "5. Extremely important" }));

  const IMPORTANCE_OPTIONS = [
    { key: PLEASE_SELECT_OPTION, value: "" },
    { key: IMPORTANCE_OPTION_1, value: "1" },
    { key: IMPORTANCE_OPTION_2, value: "2" },
    { key: IMPORTANCE_OPTION_3, value: "3" },
    { key: IMPORTANCE_OPTION_4, value: "4" },
    { key: IMPORTANCE_OPTION_5, value: "5" },
  ];

  // Understanding
  const UNDERSTANDING_OPTION_1 = i18n._(t({ message: "1. Very difficult" }));
  const UNDERSTANDING_OPTION_2 = i18n._(t({ message: "2. Difficult" }));
  const UNDERSTANDING_OPTION_3 = i18n._(t({ message: "3. Neutral" }));
  const UNDERSTANDING_OPTION_4 = i18n._(t({ message: "4. Easy" }));
  const UNDERSTANDING_OPTION_5 = i18n._(t({ message: "5. Very easy" }));

  const UNDERSTANDING_OPTIONS = [
    { key: PLEASE_SELECT_OPTION, value: "" },
    { key: UNDERSTANDING_OPTION_1, value: "1" },
    { key: UNDERSTANDING_OPTION_2, value: "2" },
    { key: UNDERSTANDING_OPTION_3, value: "3" },
    { key: UNDERSTANDING_OPTION_4, value: "4" },
    { key: UNDERSTANDING_OPTION_5, value: "5" },
  ];

  return {
    experienceOptions: EXPERIENCE_OPTIONS,
    importanceOptions: IMPORTANCE_OPTIONS,
    understandingOptions: UNDERSTANDING_OPTIONS,
  };
};
