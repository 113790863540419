import {
  AlignItems,
  Box,
  ButtonGutter,
  ButtonLayout,
  ButtonSize,
  ButtonVariant,
  Color,
  ColorPreset,
  Glyph,
  IconButton,
  JustifyContent,
  Link,
  MoneyText,
  P,
  Space,
  TypePreset,
  Visibility,
  Text,
  Tag,
  TagVariant,
  TagColor,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { useRouter } from "next/router";
import { forwardRef, useCallback } from "react";
import { useUserPermissions } from "src/common/hooks/user-permissions/useUserPermissions";
import { TrackingEvent } from "src/common/trackingEvents";
import { ToTranslate } from "src/components/i18n";
import {
  ContentName,
  DismissibleContent,
} from "src/components/layout/DismissibleContent";
import { useSegment } from "src/components/third-parties/Segment/useSegment";
import { useOrganisation } from "src/queries/organisation";

const bannerName = "Pipe promotion";

export const PipeBanner = forwardRef<HTMLDivElement, {}>((_props, ref) => {
  const organisation = useOrganisation();
  const userPermissions = useUserPermissions();
  const userIsAdmin = !!userPermissions.isAdmin;
  const { sendEvent } = useSegment();
  const router = useRouter();

  const triggerBannerViewedEvent = useCallback(() => {
    sendEvent(TrackingEvent.BANNER_VIEWED, {
      page: router.pathname,
      name: bannerName,
    });
    // TODO: Fix exhaustive dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.pathname]);

  if (!organisation?.pipe_amount || !userIsAdmin) {
    return null;
  }

  const {
    name: businessName,
    pipe_amount: amount,
    pipe_redemption_token: redemptionToken,
  } = organisation;

  const redeemHref = redemptionToken
    ? `https://app.pipe.com/partner/par_11Ujty5HkEi29hoR/redeem?token=${redemptionToken}&utm_source=banner`
    : `https://app.pipe.com/login?partner=banner`;

  return (
    <DismissibleContent
      ref={ref}
      name={ContentName.PipeBannerDismissed}
      onContentMount={triggerBannerViewedEvent}
      renderContent={(dismiss) => {
        const closeButton = (
          <IconButton
            icon={Glyph.Close}
            label={<Trans id="Close">Close</Trans>}
            variant={ButtonVariant.TextOnLight}
            onClick={() => {
              sendEvent(TrackingEvent.BANNER_DISMISSED, {
                page: router.pathname,
                name: bannerName,
              });
              dismiss();
            }}
            size={{ base: ButtonSize.Sm, gutters: ButtonGutter.Sm }}
          />
        );

        return (
          <Box
            gutterH={1}
            gutterV={1}
            borderRadius={1}
            layout="flex"
            alignItems={[AlignItems.Start, null, AlignItems.Center]}
            justifyContent={JustifyContent.SpaceBetween}
            bg={Color.Ocean_100}
            width="100%"
          >
            <Box
              layout="flex"
              flexDirection={["column", null, "row"]}
              justifyContent={JustifyContent.SpaceBetween}
              flexGrow={1}
              width="100%"
              spaceAfter={[0, null, 1]}
            >
              <Box
                layout="flex"
                flexDirection={["column", null, "row"]}
                spaceBelow={[1, null, 0]}
                alignItems={[AlignItems.Start, null, AlignItems.Center]}
              >
                <Box
                  layout="flex"
                  justifyContent={JustifyContent.SpaceBetween}
                  flexGrow={1}
                  width={["100%", null, "initial"]}
                >
                  <Tag variant={TagVariant.Solid} color={TagColor.Primary}>
                    <Text textTransform="uppercase">
                      <Trans id="new">New</Trans>
                    </Text>
                  </Tag>

                  <Visibility visible={["block", null, "none"]}>
                    {closeButton}
                  </Visibility>
                </Box>
                <Space layout="inline" h={1} />
                <P
                  color={ColorPreset.TextOnLight_01}
                  preset={TypePreset.Body_02}
                  spaceAbove={[1, null, 0]}
                >
                  <ToTranslate>
                    <>Unlock up to&nbsp;</>
                    <MoneyText
                      format="short"
                      amount={(amount ?? 0) / 100}
                      currency="GBP"
                      locale="en"
                    />
                    <>&nbsp;in capital for {businessName}</>
                  </ToTranslate>
                </P>
              </Box>
              <Space layout="inline" h={1} />
              <Link
                href={redeemHref}
                variant={ButtonVariant.SecondaryOnLight}
                size={ButtonSize.Sm}
                layout={[ButtonLayout.Full, null, ButtonLayout.Inline]}
                target="_blank"
                onClick={() => {
                  sendEvent(TrackingEvent.BANNER_PRIMARY_CTA_CLICKED, {
                    page: router.pathname,
                    name: bannerName,
                    destination: redeemHref,
                  });
                }}
              >
                <ToTranslate>View offer</ToTranslate>
              </Link>
            </Box>
            <Visibility visible={["none", null, "block"]}>
              {closeButton}
            </Visibility>
          </Box>
        );
      }}
    />
  );
});
