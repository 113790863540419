import { ReactNode, createContext, useContext, useState } from "react";
import { Currency } from "@gocardless/api/dashboard/types";
import { DateRange } from "src/common/date-range-helper";
import { useOrganisation } from "src/queries/organisation";
import { useReportingMetric } from "@gocardless/api/dashboard/reporting";
import { useDefaultCreditor } from "src/queries/creditor";
import { castStringToDate } from "src/common/date-helper";

import { getDatesFromRange } from "./DateRangeMenu/utils";
import { reportingDataWidgetTypes } from "./DataWidget/utils";
import { ReportingChartType, reportingChartTypes } from "./ChartWidget/utils";

interface ReportingContextProviderProps {
  children: ReactNode;
}

export const defaultValues = {
  selectedCurrency: Currency.Gbp,
  setSelectedCurrency: () => {},
  selectedChartType: reportingChartTypes.collectedPayments,
  setSelectedChartType: () => {},
  startDateFilter: "",
  setStartDateFilter: () => {},
  endDateFilter: "",
  setEndDateFilter: () => {},
  failedRequestKeys: [],
  setFailedRequestKeys: () => {},
  updatedAtDates: [],
  setUpdatedAtDates: () => {},
  isChartWidgetLoading: false,
  areDataWidgetsLoading: false,
};

export const ReportingContext = createContext<
  ReturnType<typeof useReportingContextValues>
>({
  ...defaultValues,
});

export const useReportingContextValues = () => {
  const { startDate, endDate } = getDatesFromRange(DateRange.Last30Days);
  const organisation = useOrganisation();

  const [selectedCurrency, setSelectedCurrency] = useState(
    organisation?.domestic_currency as Currency
  );
  const [startDateFilter, setStartDateFilter] = useState(startDate);
  const [endDateFilter, setEndDateFilter] = useState(endDate);

  const [failedRequestKeys, setFailedRequestKeys] = useState<string[]>([]);
  const [updatedAtDates, setUpdatedAtDates] = useState<string[]>([]);

  const [selectedChartType, setSelectedChartType] =
    useState<ReportingChartType>("collected_payments");

  const defaultCreditorId = useDefaultCreditor()?.id ?? "";

  const { isLoading: isActiveCustomersDataWidgetLoading } = useReportingMetric(
    reportingDataWidgetTypes.activeCustomers,
    {
      creditor: defaultCreditorId,
    }
  );
  const { isLoading: isFailedPaymentsDataWidgetLoading } = useReportingMetric(
    reportingDataWidgetTypes.failedPayments,
    {
      creditor: defaultCreditorId,
    }
  );
  const { isLoading: isPendingPaymentsDataWidgetLoading } = useReportingMetric(
    reportingDataWidgetTypes.pendingPayments,
    {
      creditor: defaultCreditorId,
    }
  );
  const { isLoading: isPendingPayoutsDataWidgetLoading } = useReportingMetric(
    reportingDataWidgetTypes.pendingPayouts,
    {
      creditor: defaultCreditorId,
    }
  );

  const { isLoading: isChartLoading, isValidating: isChartValidating } =
    useReportingMetric(selectedChartType, {
      creditor: defaultCreditorId,
      start_date: castStringToDate(startDateFilter),
      end_date: castStringToDate(endDateFilter),
    });

  const isChartWidgetLoading = isChartLoading || isChartValidating;

  const areDataWidgetsLoading =
    isActiveCustomersDataWidgetLoading ||
    isFailedPaymentsDataWidgetLoading ||
    isPendingPaymentsDataWidgetLoading ||
    isPendingPayoutsDataWidgetLoading;

  return {
    selectedCurrency,
    setSelectedCurrency,
    selectedChartType,
    setSelectedChartType,
    startDateFilter,
    setStartDateFilter,
    endDateFilter,
    setEndDateFilter,
    failedRequestKeys,
    setFailedRequestKeys,
    updatedAtDates,
    setUpdatedAtDates,
    isChartWidgetLoading,
    areDataWidgetsLoading,
  };
};

export const ReportingContextProvider = ({
  children,
}: ReportingContextProviderProps) => {
  const contextValues = useReportingContextValues();

  return (
    <ReportingContext.Provider
      value={{
        ...contextValues,
      }}
    >
      {children}
    </ReportingContext.Provider>
  );
};

export const useReportingContext = () => {
  const context = useContext(ReportingContext);

  if (context === undefined) {
    throw new Error(
      "useReportingContext must be used within a ReportingContextProvider"
    );
  }

  return context;
};
