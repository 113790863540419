import { ChangeEventHandler, useId } from "react";
import {
  AlignItems,
  Box,
  Dropdown,
  FieldSet,
  FontWeight,
  Glyph,
  Menu,
  MenuRole,
  Radio,
  Space,
  Text,
  ToggleControlPosition,
  TypePreset,
  TypeScale,
  PlainButton,
  Icon,
  HoverEffect,
} from "@gocardless/flux-react";
import { dateFilterToText } from "src/common/date-range-helper";
import { useSegment } from "src/components/third-parties/Segment/useSegment";
import { TrackingEvent } from "src/common/trackingEvents";
import { useLingui } from "@lingui/react";
import { t } from "@lingui/macro";

import { useMenuStyle } from "../../utils/style";
import { useReportingContext } from "../ReportingContextProvider";

import {
  DateRangeOption,
  dateRangeOptions,
  getSelectedDateRangeEvent,
  getDatesFromRange,
  getDateRangeFromDateFilters,
} from "./utils";

export const DateRangeMenu = () => {
  const {
    isChartWidgetLoading,
    startDateFilter,
    setStartDateFilter,
    endDateFilter,
    setEndDateFilter,
  } = useReportingContext();

  const { sendEvent } = useSegment();
  const { i18n } = useLingui();
  const menuStyle = useMenuStyle();
  const dropdownId = useId();

  const dateRangeMap = dateFilterToText();

  const selectedDateRange = getDateRangeFromDateFilters(
    startDateFilter,
    endDateFilter
  );

  const handleChangeDateRange: ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const dateRange = event.target.value as DateRangeOption;
    const { startDate, endDate } = getDatesFromRange(dateRange);
    setStartDateFilter(startDate);
    setEndDateFilter(endDate);
    sendEvent(getSelectedDateRangeEvent(dateRange));
  };

  return (
    <Box layout="flex" alignItems={AlignItems.Center}>
      <Dropdown
        controls={dropdownId}
        trigger={(triggerProps) => (
          <PlainButton
            {...triggerProps}
            aria-label={i18n._(
              t({
                message: "Select preset chart date range",
              })
            )}
            disabled={isChartWidgetLoading}
            effect={HoverEffect.TextDecoration}
            onClick={() => {
              triggerProps.onClick();
              sendEvent(
                TrackingEvent.MERCHANT_DASHBOARD_REPORTING_PRESET_DATE_PICKER_CLICKED
              );
            }}
          >
            <Box layout="flex" alignItems={AlignItems.Center}>
              <Text
                data-testid="selected-date-range"
                preset={TypePreset.Body_01}
                weight={FontWeight.SemiBold}
              >
                {dateRangeMap[selectedDateRange]}
              </Text>
              <Space h={0.5} layout="inline" />
              <Icon name={Glyph.ChevronDown} size="12px" />
            </Box>
          </PlainButton>
        )}
      >
        {({ close }) => (
          <Menu.Root id={dropdownId} role={MenuRole.Menu}>
            <FieldSet>
              <Box css={menuStyle} layout="flex" flexDirection="column">
                {Object.keys(dateRangeOptions).map((option) => {
                  const dateRangeOption = option as DateRangeOption;

                  return (
                    <Radio
                      key={option}
                      checked={
                        selectedDateRange === dateRangeOptions[dateRangeOption]
                      }
                      controlPosition={ToggleControlPosition.End}
                      name="dateRange"
                      onChange={(event) => {
                        handleChangeDateRange(event);
                        close();
                      }}
                      value={option}
                    >
                      <Text size={TypeScale.Size_02} weight={FontWeight.Normal}>
                        {dateRangeMap[dateRangeOption]}
                      </Text>
                    </Radio>
                  );
                })}
              </Box>
            </FieldSet>
          </Menu.Root>
        )}
      </Dropdown>
    </Box>
  );
};
