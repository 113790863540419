import {
  Box,
  Breakpoint,
  H2,
  Interpose,
  Separator,
  TextAlign,
  TypePreset,
} from "@gocardless/flux-react";
import InviteCustomerNew from "src/assets/png/dashboard/how-to-invite-customers-new.png";
import SaveTimeWithSubscriptionTemplateNew from "src/assets/png/dashboard/save-time-with-subscription-template-new.png";
import OnboardInBulkNew from "src/assets/png/dashboard/onboard-in-bulk-new.png";
import { useIsBreakpoint } from "src/common/hooks/useIsBreakpoint";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";

import { HomepageCard } from "../../components/HomepageCard";
import { Section } from "../../components/Section";

import { CardContent } from "./CardContent";

const useItems = () => {
  const { i18n } = useLingui();

  return [
    {
      name: "SaveTimeWithSubscriptionTemplate",
      imagePath: SaveTimeWithSubscriptionTemplateNew,
      description: i18n._(
        t({
          id: "homepage.needhelp.savetimewithsubscriptiontemplate-description",
          message: "Save time with Subscription templates",
        })
      ),
      link: "https://hub.gocardless.com/s/article/Using-plan-templates?language=en_GB",
      linkLabel: i18n._(
        t({
          id: "homepage.needhelp.savetimewithsubscriptiontemplate-linkLabel",
          message: "Read more",
        })
      ),
    },
    {
      name: "OnboardInBulk",
      imagePath: OnboardInBulkNew,
      description: i18n._(
        t({
          id: "homepage.needhelp.onboardinbulk-description",
          message: "How to onboard your customers to GoCardless in bulk",
        })
      ),
      link: "https://gocardless.com/guides/posts/switching-to-gocardless/",
      linkLabel: i18n._(
        t({
          id: "homepage.needhelp.onboardinbulk-linkLabel",
          message: "Read more",
        })
      ),
    },
    {
      name: "InviteCustomer",
      imagePath: InviteCustomerNew,
      description: i18n._(
        t({
          id: "homepage.needhelp.invitecustomer-description",
          message: "How to invite your customers to pay with GoCardless",
        })
      ),
      link: "https://gocardless.com/guides/moving-customers-to-direct-debit/explaining-gocardless-to-customers/",
      linkLabel: i18n._(
        t({
          id: "homepage.needhelp.invitecustomer-linkLabel",
          message: "View all",
        })
      ),
    },
  ];
};

export const NeedHelp = () => {
  const items = useItems();
  const isMobileOrTabletBreakpoint = useIsBreakpoint({ max: Breakpoint.Md });
  return (
    <Box layout="flex" flexDirection="column">
      <H2
        preset={TypePreset.Heading_04}
        textAlign={TextAlign.Left}
        spaceBelow={1}
      >
        <Trans id="Need help">Need help?</Trans>
      </H2>

      {isMobileOrTabletBreakpoint ? (
        <HomepageCard>
          <Section>
            <Interpose node={<Separator spacing={0} />}>
              {items.map((item) => (
                <CardContent key={item.name} {...item} />
              ))}
            </Interpose>
          </Section>
        </HomepageCard>
      ) : (
        <Section>
          {items.map((item) => (
            <HomepageCard key={item.name}>
              <CardContent {...item} />
            </HomepageCard>
          ))}
        </Section>
      )}
    </Box>
  );
};
