import {
  Box,
  Breakpoint,
  Button,
  ButtonGroup,
  ButtonLayout,
  ButtonVariant,
  ColorPreset,
  ColorScheme,
  Dialog,
  DialogProps,
  FontWeight,
  H2,
  Link,
  P,
  Table,
  Tag,
  TagColor,
  TagVariant,
  Text,
  useTheme,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { useIsBreakpoint } from "src/common/hooks/useIsBreakpoint";

import { LEARN_MORE_BASE_URL } from "../../utils/common";

interface EducationalDialogProps {
  isOpen: NonNullable<DialogProps["open"]>;
  onClose: NonNullable<DialogProps["closeAction"]>["onClose"];
}

export const EducationalDialog: React.FC<EducationalDialogProps> = ({
  isOpen,
  onClose,
}) => {
  const isSmallBreakpointOrHigher = useIsBreakpoint({
    min: Breakpoint.Sm,
  });

  const { theme } = useTheme();

  return (
    <Dialog
      header={
        <Box>
          <Tag
            variant={TagVariant.Tinted}
            colorScheme={ColorScheme.OnLight}
            color={TagColor.Info}
          >
            <Trans>New feature</Trans>
          </Tag>
          <H2 size={6} spaceAbove={1}>
            <Trans>Where can I view the previous data?</Trans>
          </H2>
        </Box>
      }
      footer={
        <ButtonGroup arrangement={["column-center-reverse", "row-end"]}>
          <Link
            href={LEARN_MORE_BASE_URL}
            layout={[ButtonLayout.Full, ButtonLayout.Inline]}
            target="_blank"
            variant={
              isSmallBreakpointOrHigher
                ? ButtonVariant.TextOnLight
                : ButtonVariant.SecondaryOnLight
            }
          >
            <Trans id="Learn more">Learn more</Trans>
          </Link>
          <Button
            layout={[ButtonLayout.Full, ButtonLayout.Inline]}
            onClick={onClose}
            variant={ButtonVariant.PrimaryOnLight}
          >
            <Trans>Got it</Trans>
          </Button>
        </ButtonGroup>
      }
      open={isOpen}
      closeAction={{
        onClose,
        label: <Trans id="Close">Close</Trans>,
      }}
    >
      <P size={2} spaceBelow={1.5}>
        <Trans>
          When viewing the new reporting feature, you can find the same data
          found in the previous version under the updated names outlined below:
        </Trans>
      </P>

      <Table.Container bgcolor={theme.color(ColorPreset.BackgroundLight_01)}>
        <Table.Head>
          <Table.Row>
            <Table.HeadCell>
              <Text size={2}>
                <Trans>Previous</Trans>
              </Text>
            </Table.HeadCell>

            <Table.HeadCell>
              <Text size={2}>
                <Trans id="new">New</Trans>
              </Text>
            </Table.HeadCell>
          </Table.Row>
        </Table.Head>

        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <Text size={2}>
                <Trans>Pending</Trans>
              </Text>
            </Table.Cell>
            <Table.Cell>
              <Text size={2} layout="block" weight={FontWeight.SemiBold}>
                Pending payments
              </Text>
              <Text color={ColorPreset.TextOnLight_03} size={1}>
                <Trans>
                  All payments that are either pending submission or have been
                  submitted but not yet charged.
                </Trans>
              </Text>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>
              <Text size={2}>
                <Trans>Collected</Trans>
              </Text>
            </Table.Cell>
            <Table.Cell>
              <Text size={2} layout="block" weight={FontWeight.SemiBold}>
                Confirmed funds
              </Text>
              <Text color={ColorPreset.TextOnLight_03} size={1}>
                <Trans>
                  Collected payments that have been confirmed, minus fees and
                  unclaimed debits for refunds, failures and chargebacks. This
                  balance can be negative, as payment failures and chargebacks
                  may happen after funds have been paid out.
                </Trans>
              </Text>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>
              <Text size={2}>
                <Trans>Payouts</Trans>
              </Text>
            </Table.Cell>
            <Table.Cell>
              <Text size={2} layout="block" weight={FontWeight.SemiBold}>
                <Trans>Pending payouts</Trans>
              </Text>
              <Text color={ColorPreset.TextOnLight_03} size={1}>
                <Trans>
                  All payouts that have been confirmed and thus are ready for
                  the next payout.
                </Trans>
              </Text>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table.Container>
    </Dialog>
  );
};
