import {
  useUserShowSelf,
  useUserUpdateSelf,
} from "@gocardless/api/dashboard/user";
import { useToastNotification } from "src/common/notifications/useToastNotification";
import { errorNotificationHandler } from "src/common/notifications/notificationErrorHandlers";
import { useLoadIntercom } from "src/common/hooks/useLoadIntercom";
import { useOptimizelyVariation } from "src/components/third-parties/Optimizely/useOptimizelyVariation";
import { OptimizelyFlag } from "src/components/third-parties/Optimizely/constants";

import { PersonalLanguageConfig } from "../types";

export const useLanguageSettings = () => {
  const { triggerErrorNotification } = useToastNotification();
  const { data: userDetails, mutate: updateUser } = useUserShowSelf();
  const { loadIntercom } = useLoadIntercom();
  const { isVariationOn, clientReady } = useOptimizelyVariation({
    flag: OptimizelyFlag.BI_ZENDESK_MIGRATION,
  });
  const scope = userDetails?.users?.scope;

  const [submit] = useUserUpdateSelf({
    onSuccess: (response) => {
      if (response) {
        // Conditional loading of Intercom based on the feature flag
        if (clientReady) {
          if (isVariationOn) {
            // Not load intercom
          } else {
            loadIntercom(response.users, scope);
          }
        }
        updateUser(response, false);
      }
    },
    onError: async (err) => {
      errorNotificationHandler(err, triggerErrorNotification);
    },
  });

  const updateUserLanguage = (updateLanguage: PersonalLanguageConfig) => {
    const { language } = updateLanguage;
    submit({ language });
  };

  return {
    userDetails,
    updateUserLanguage,
  };
};
