import { HTTPError } from "@gocardless/api/utils/api";
import { useEffect } from "react";
import { captureException } from "src/utils/sentry";

import { useReportingContext } from "../components/ReportingContextProvider";

export const useReportingError = (error?: HTTPError, key?: string) => {
  const { failedRequestKeys, setFailedRequestKeys } = useReportingContext();

  useEffect(() => {
    if (error && key && !failedRequestKeys.includes(key)) {
      setFailedRequestKeys((prevState) => [...prevState, key]);
      captureException({ error });
    }
  }, [error, key, failedRequestKeys, setFailedRequestKeys]);
};
