import {
  AlignItems,
  Box,
  ButtonGutter,
  ButtonSize,
  ButtonVariant,
  Color,
  ColorPreset,
  Glyph,
  HoverEffect,
  Icon,
  IconButton,
  JustifyContent,
  P,
  PlainLink,
  Space,
  TypePreset,
  Visibility,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { forwardRef } from "react";
import { isAfter } from "src/common/relativeDate";
import {
  ContentName,
  DismissibleContent,
} from "src/components/layout/DismissibleContent";
import { useOrganisation } from "src/queries/organisation";

export const LegalTermsBanner = forwardRef<HTMLDivElement, {}>(
  (_props, ref) => {
    const organisation = useOrganisation();
    const organisationCreated =
      organisation?.created_at && new Date(organisation?.created_at);

    const orgCreatedBeforeCutoff = organisationCreated
      ? organisationCreated < new Date("2024-07-01")
      : false;

    const isAfterDisplayWindow = isAfter("2024-09-01T00:00:00.000Z");

    return orgCreatedBeforeCutoff && !isAfterDisplayWindow ? (
      <DismissibleContent
        ref={ref}
        name={ContentName.LegalTermsDismissed}
        renderContent={(dismiss) => (
          <Box
            gutterH={1}
            gutterV={1}
            borderRadius={1}
            layout="flex"
            alignItems={[AlignItems.Start, null, AlignItems.Center]}
            justifyContent={JustifyContent.SpaceBetween}
            bg={Color.Sunrise_100}
          >
            <Box
              layout="flex"
              flexDirection={["column", null, "row"]}
              alignItems={[AlignItems.Start, null, AlignItems.Center]}
            >
              <Box
                layout="flex"
                gutterH={0.5}
                gutterV={0.25}
                alignItems={AlignItems.Center}
              >
                <Icon name={Glyph.Warning} size="16px" />
              </Box>
              <Space layout="inline" h={1} />
              <Visibility visible={["block", null, "none"]}>
                <Space v={1} />
              </Visibility>
              <P color={ColorPreset.TextOnLight_01} preset={TypePreset.Body_02}>
                <Trans id="legal-terms-banner.terms-changing">
                  Please note that the terms found in the Payment Services
                  Agreement are changing. Further information can be found
                </Trans>{" "}
                <PlainLink
                  href="https://gocardless.com/legal"
                  target="_blank"
                  effect={HoverEffect.TextDecoration}
                  textDecoration="underline"
                >
                  <Trans id="legal-terms-banner.here">here</Trans>
                </PlainLink>
                .
              </P>
            </Box>
            <Box>
              <IconButton
                icon={Glyph.Close}
                label={<Trans id="close">Close</Trans>}
                variant={ButtonVariant.TextOnLight}
                onClick={() => dismiss()}
                size={{ base: ButtonSize.Sm, gutters: ButtonGutter.Sm }}
                color={Color.Evergreen_100}
              />
            </Box>
          </Box>
        )}
      />
    ) : null;
  }
);
