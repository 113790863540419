import { DateRange } from "src/common/date-range-helper";
import { TrackingEvent } from "src/common/trackingEvents";
import { getLocalTimeZone, today } from "@internationalized/date";

export const dateRangeOptions = {
  [DateRange.Last7Days]: DateRange.Last7Days,
  [DateRange.Last30Days]: DateRange.Last30Days,
  [DateRange.Last90Days]: DateRange.Last90Days,
} as const;

export type DateRangeOption =
  (typeof dateRangeOptions)[keyof typeof dateRangeOptions];

export function getDatesFromRange(range: DateRangeOption) {
  // Most recent metric data will be from the previous day
  const yesterday = today(getLocalTimeZone()).subtract({ days: 1 });

  let startDate: string;

  switch (range) {
    case DateRange.Last7Days:
      startDate = yesterday.subtract({ days: 7 }).toString();
      break;
    case DateRange.Last30Days:
      startDate = yesterday.subtract({ months: 1 }).toString();
      break;
    case DateRange.Last90Days:
      startDate = yesterday.subtract({ months: 3 }).toString();
      break;
  }

  return { startDate, endDate: yesterday.toString() };
}

export const getSelectedDateRangeEvent = (dateRange: DateRangeOption) => {
  switch (dateRange) {
    case DateRange.Last7Days:
      return TrackingEvent.MERCHANT_DASHBOARD_REPORTING_LAST_7_DAYS_DATE_RANGE_SELECTED;

    case DateRange.Last30Days:
      return TrackingEvent.MERCHANT_DASHBOARD_REPORTING_LAST_MONTH_DATE_RANGE_SELECTED;

    case DateRange.Last90Days:
      return TrackingEvent.MERCHANT_DASHBOARD_REPORTING_LAST_3_MONTHS_DATE_RANGE_SELECTED;
  }
};

export function getDateRangeFromDateFilters(
  startDate: string,
  endDate: string
): DateRangeOption | DateRange {
  const yesterday = today(getLocalTimeZone()).subtract({ days: 1 });

  const last7days = yesterday.subtract({ days: 7 }).toString();
  const last30Days = yesterday.subtract({ days: 30 }).toString();
  const last90Days = yesterday.subtract({ days: 90 }).toString();

  if (endDate === yesterday.toString()) {
    if (startDate === last7days) {
      return DateRange.Last7Days;
    }
    if (startDate === last30Days) {
      return DateRange.Last30Days;
    }

    if (startDate === last90Days) {
      return DateRange.Last90Days;
    }
  }

  return DateRange.Custom; // Default to Custom if no match
}
