import { ErrorInterface, Error, Errors } from "@gocardless/api/utils/error";
import { NotificationPayload } from "src/common/notifications/useToastNotification";
import {
  IntervalUnit,
  Currency,
  DayOfWeek,
  Month,
} from "@gocardless/api/dashboard/types";
import { t } from "@lingui/macro";
import { I18n } from "@lingui/core";
import { currencyToScheme, Scheme } from "src/common/scheme";
import { dayOfWeekAsString } from "src/common/date-time";
import { monthToName } from "src/common/months";
import { getDayOfMonthOrdinal } from "src/utils/planDescription";

export const toKebabCase = (tabName: string): string =>
  tabName.replace(/\s/g, "-").toLowerCase();

export const getAmountTypeError = (
  error: Error,
  qualifiesForPaymentLimitIncrease: boolean,
  currency: string
): string => {
  const link =
    "https://support.gocardless.com/hc/en-gb/requests/new?ticket_form_id=360000040469";
  const metadata = error.metadata;
  // To maintain the old functionality until all amount errors have a `metadata` object.
  // Should remove once all amount errors include a `metadata` object.
  if (!metadata) {
    if (qualifiesForPaymentLimitIncrease) {
      return `Amount is greater than the maximum permitted amount - contact GoCardless using this ${link} if you would like to take larger payments`;
    } else {
      return "Amount is greater than the maximum permitted amount";
    }
  }

  // Assuming that `metadata` object will have only a single key-value pair,
  // where the `value` will always be the actual amount.
  const amountInPence = Number(Object.values(metadata)[0]) || 0;

  const amount = amountInPence / 100;
  const errorType = error?.message;
  if (
    errorType === "amount_greater_than_maximum_error" ||
    errorType === "is greater than the permitted scheme maximum"
  ) {
    if (qualifiesForPaymentLimitIncrease) {
      return `Amount is greater than the maximum permitted amount of ${amount} ${currency} - contact GoCardless using this ${link} if you would like to take larger payments`;
    } else {
      return `Amount is greater than the maximum permitted amount of ${amount} ${currency}`;
    }
  } else if (errorType === "greater_than_scheme_max") {
    return `Amount is greater than the maximum scheme permitted amount of ${amount} ${currency}`;
  }
  return "";
};

export const getInterval = (
  i18n: I18n,
  interval?: number | string,
  intervalUnit?: IntervalUnit,
  dayOfWeek?: DayOfWeek,
  dayOfMonth?: string,
  month?: Month
): string => {
  const WEEKS = i18n._(t({ id: "interval-weeks", message: "weeks" }));
  const MONTHS = i18n._(t({ id: "interval-months", message: "months" }));

  const INTERVAL_UNIT_WEEKLY = i18n._(t({ id: "weekly", message: "weekly" }));
  const INTERVAL_UNIT_MONTHLY = i18n._(
    t({ id: "monthly", message: "monthly" })
  );
  const INTERVAL_UNIT_YEARLY = i18n._(t({ id: "yearly", message: "yearly" }));

  const intervalUnitConfig: Record<IntervalUnit, string> = {
    weekly: INTERVAL_UNIT_WEEKLY,
    monthly: INTERVAL_UNIT_MONTHLY,
    yearly: INTERVAL_UNIT_YEARLY,
  };
  const intervalConfig: Record<IntervalUnit, string> = {
    weekly: WEEKS,
    monthly: MONTHS,
    yearly: "",
  };
  if (!intervalUnit) {
    return "";
  }
  if (interval && intervalUnit) {
    if (intervalUnit === IntervalUnit.Weekly && dayOfWeek) {
      return Number(interval) === 1
        ? i18n._(
            t({
              id: "interval-with-every-1-on-day-of-week",
              message: `${
                intervalUnitConfig[intervalUnit]
              } on ${dayOfWeekAsString(dayOfWeek)}`,
            })
          )
        : i18n._(
            t({
              id: "interval-with-every-n-on-day-of-week",
              message: `Every ${interval} ${
                intervalConfig[intervalUnit]
              } on ${dayOfWeekAsString(dayOfWeek)}`,
            })
          );
    }

    if (intervalUnit === IntervalUnit.Monthly && dayOfMonth) {
      const dayOfMonthOrdinal = getDayOfMonthOrdinal(Number(dayOfMonth));

      return Number(interval) === 1
        ? i18n._(
            t({
              id: "interval-with-every-1-on-day-of-month",
              message: `${intervalUnitConfig[intervalUnit]} on the ${dayOfMonthOrdinal}`,
            })
          )
        : i18n._(
            t({
              id: "interval-with-every-n-on-day-of-month",
              message: `Every ${interval} ${intervalConfig[intervalUnit]} on the ${dayOfMonthOrdinal}`,
            })
          );
    }

    if (intervalUnit === IntervalUnit.Yearly && dayOfMonth && month) {
      const dayOfMonthOrdinal = getDayOfMonthOrdinal(Number(dayOfMonth));

      return i18n._(
        t({
          id: "interval-with-every-1-on-day-of-month-of-year",
          message: `${
            intervalUnitConfig[intervalUnit]
          } on the ${dayOfMonthOrdinal} of ${monthToName()[month]}`,
        })
      );
    }

    return Number(interval) === 1
      ? `${intervalUnitConfig[intervalUnit]}`
      : i18n._(
          t({
            id: "interval-with-every",
            message: `Every ${interval} ${intervalConfig[intervalUnit]}`,
          })
        );
  } else {
    return `${intervalUnitConfig[intervalUnit]}`;
  }
};

export const getSendInviteButtonTitle = (i18n: I18n, count: number) =>
  count <= 1
    ? i18n._(
        t({
          id: "send-invite",
          message: `Send invite ( ${count} )`,
        })
      )
    : i18n._(
        t({
          id: "send-invites",
          message: `Send invites ( ${count} )`,
        })
      );

/**
 * Accepts an error payload returned by the backend when a POST to the
 * Authorisation Requests endpoint fails. This function parses the Authorisation
 * Requests which have failed to be created (given with an index of the passed
 * emails), and then matches those failures to the email addresses which have
 * failed validation. This allows us to provide more helpful error messaging to
 * merchants, showing them the name of the emails which have failed validation
 * in the UI.
 *
 * For example if the list of emails is:
 * ["test1@test.com", "test2@test.com", "test3@test.com"]
 *
 * And the request_pointers in the errors array are as follows:
 * "/authorisation_requests/0/email"
 * "/authorisation_requests/2/email"
 *
 * Then we can display "test1@test.com" and "test3@test.com" to the user in an
 * error message.
 */
export const getInvalidEmailsFromAuthRequestErrorPayload = (
  errorPayload: {
    notificationPayload: NotificationPayload;
    formError: "" | Errors | undefined;
    errorJSON: ErrorInterface | undefined;
  },
  emails: string[]
): string[] => {
  if (!Array.isArray(errorPayload.errorJSON?.errors)) {
    return [];
  }

  const invalidEmailIndices = errorPayload.errorJSON?.errors
    .filter(
      ({ field, message }) => field === "email" && message === "is not valid"
    )
    .map(({ request_pointer }) => request_pointer.match(/\d+/)?.map(Number)[0]);
  if (invalidEmailIndices && invalidEmailIndices.length > 0) {
    const emailErrors = invalidEmailIndices
      .filter((value) => value !== undefined)
      .map((index) => emails[index as number] || "");
    return emailErrors;
  }
  return [];
};

export const getLastDayOfMonth = (month: number, year: number): number => {
  const isLeapYear =
    Number.isInteger(year / 4) && !Number.isInteger(year / 100);
  const lastDayOfMonth = [
    31,
    isLeapYear ? 29 : 28,
    31,
    30,
    31,
    30,
    31,
    31,
    30,
    31,
    30,
    31,
  ];
  return lastDayOfMonth[month] || 30;
};

const PAYMENT_TIMINGS: Record<Scheme, number> = {
  [Scheme.Ach]: 4,
  [Scheme.Autogiro]: 10,
  [Scheme.Bacs]: 5,
  [Scheme.Becs]: 4,
  [Scheme.BecsNz]: 6,
  [Scheme.Betalingsservice]: 10,
  [Scheme.Pad]: 6,
  [Scheme.SepaCore]: 6,
  [Scheme.FasterPayments]: 0,
  [Scheme.PayTo]: 0,
};

export const getPaymentTiming = (currency: Currency) =>
  PAYMENT_TIMINGS[currencyToScheme[currency]];
