import {
  Currency,
  IntervalUnit,
  PlanResource,
  SubscriptionResource,
} from "@gocardless/api/dashboard/types";
import { currencyToSymbol } from "src/common/currencies";
import { i18n } from "@lingui/core";
import { selectOrdinal, t } from "@lingui/macro";
import { dayOfWeekAsString } from "src/common/date-time";
import { monthToName } from "src/common/months";

export const convertCentsToCurrency = (amount: number, currency: Currency) =>
  `${currencyToSymbol[currency]}${Math.floor(amount / 100)}.${(amount % 100)
    .toString()
    .padStart(2, "0")}`;

export const getPlanIntervalMessage = (
  intervalUnit?: IntervalUnit,
  interval?: number
) => {
  if (!intervalUnit || !interval) {
    return "";
  }

  switch (intervalUnit) {
    case IntervalUnit.Monthly:
      if (interval === 1) {
        return i18n._(
          t({ id: "request-payment.every-month", message: `every month` })
        );
      }
      return i18n._(
        t({
          id: "request-payment.every-x-months",
          message: `every ${interval || ""} months`,
        })
      );
    case IntervalUnit.Weekly:
      if (interval === 1) {
        return i18n._(
          t({ id: "request-payment.every-week", message: `every week` })
        );
      }
      return i18n._(
        t({
          id: "request-payment.every-x-weeks",
          message: `every ${interval || ""} weeks`,
        })
      );
    case IntervalUnit.Yearly:
      if (interval === 1) {
        return i18n._(
          t({ id: "request-payment.every-year", message: `every year` })
        );
      }
      return i18n._(
        t({
          id: "request-payment.every-x-years",
          message: `every ${interval || ""} years`,
        })
      );
  }
};

export const getDayOfMonth = (day: number): string =>
  day === 29 || day === -1
    ? i18n._(t({ id: "last-day", message: "last day" }))
    : getDayOfMonthOrdinal(day);

export const getDayOfMonthOrdinal = (day: number): string =>
  i18n._(
    t({
      id: "day-of-month-ordinal",
      message: selectOrdinal(day, {
        one: "#st",
        two: "#nd",
        few: "#rd",
        many: "#th",
        other: "#th",
      }),
    })
  );

export const getPlanDescription = (
  resource: PlanResource | SubscriptionResource
) => {
  const { amount, currency, interval_unit: intervalUnit, interval } = resource;

  const baseResult = `${convertCentsToCurrency(
    amount as number,
    currency as Currency
  )} ${getPlanIntervalMessage(intervalUnit, interval as number)}`;

  if (hasSpecifiedCollectionDate(resource)) {
    const collectionDate = planCollectionDate(resource);
    return `${baseResult} ${collectionDate}`;
  } else {
    return baseResult;
  }
};

const isSubscriptionType = (
  resource: PlanResource | SubscriptionResource
): resource is SubscriptionResource => !!resource.id?.startsWith("SB");

const hasSpecifiedCollectionDate = (
  resource: PlanResource | SubscriptionResource
): boolean => {
  if (isSubscriptionType(resource)) {
    return Boolean(resource.day_of_month || resource.month);
  }

  return Boolean(
    resource.day_of_month || resource.day_of_week || resource.month
  );
};

const planCollectionDate = (
  resource: PlanResource | SubscriptionResource
): string => {
  if (!isSubscriptionType(resource)) {
    if (resource.day_of_week) {
      const dayOfWeek = dayOfWeekAsString(resource.day_of_week);
      return i18n._(
        t({ id: "plan-timings.on-day-of-week", message: `on ${dayOfWeek}` })
      );
    }
  }

  if (resource.day_of_month && resource.month) {
    const dayOfMonth = getDayOfMonth(Number(resource.day_of_month));
    const monthName = monthToName()[resource.month];
    return i18n._(
      t({
        id: "plan-timings.on-day-of-specified-month",
        message: `on the ${dayOfMonth} of ${monthName}`,
      })
    );
  }
  const dayOfMonth = getDayOfMonth(Number(resource.day_of_month));
  return i18n._(
    t({
      id: "plan-timings.on-day-of-month",
      message: `on the ${dayOfMonth}`,
    })
  );
};

/**
 * Returns a string which describes the plan timings in plain English. For
 * example a monthly plan collected on the 25th of the month would return
 * "Every month via Direct Debit on the 25th".
 */
export const getPlanTimings = (
  resource: PlanResource | SubscriptionResource,
  includePaymentMethod = true
): string => {
  const viaDirectDebit = i18n._(
    t({
      id: "plan.description-via-direct-debit",
      message: "via Direct Debit",
    })
  );

  const interval = getPlanIntervalMessage(
    resource.interval_unit,
    Number(resource.interval)
  );
  let baseResult = interval[0]?.toUpperCase() + interval.slice(1);
  if (includePaymentMethod) {
    baseResult = `${baseResult} ${viaDirectDebit}`;
  }

  if (hasSpecifiedCollectionDate(resource)) {
    const collectionDate = planCollectionDate(resource);
    return `${baseResult} ${collectionDate}`;
  } else {
    return baseResult;
  }
};
