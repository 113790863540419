import {
  AlignItems,
  Box,
  ColorPreset,
  FontWeight,
  JustifyContent,
  P,
  PlainLink,
  HoverEffect,
  TypePreset,
  Color,
  Visibility,
  Icon,
  Glyph,
} from "@gocardless/flux-react";
import { useCallback } from "react";
import Image from "next/image";
import { useSegment } from "src/components/third-parties/Segment/useSegment";
import { TrackingEvent } from "src/common/trackingEvents";
import { useRouter } from "next/router";

export const CardContent: React.FC<{
  imagePath: string;
  description: string;
  link: string;
  linkLabel: string;
}> = ({ imagePath, description, link, linkLabel }) => {
  const { sendEvent } = useSegment();
  const router = useRouter();
  const onClick = useCallback(() => {
    sendEvent(TrackingEvent.DASHBOARD_EXTERNAL_LINK_CLICKED, {
      page: router.pathname,
      link_to: link,
    });
  }, [link, router.pathname, sendEvent]);

  return (
    <Box
      layout="flex"
      flexDirection={["row", null, "column"]}
      alignItems={[AlignItems.Center, null, AlignItems.FlexStart]}
      width="100%"
    >
      <Visibility css={{ width: "100%" }} visible={["none", null, "block"]}>
        <Box height={64} css={{ position: "relative" }}>
          <Image
            src={imagePath}
            fill
            alt={description}
            css={{ objectFit: "contain", objectPosition: "left" }}
          />
        </Box>
      </Visibility>

      <Box
        layout="flex"
        justifyContent={[JustifyContent.FlexStart, null, JustifyContent.Center]}
        spaceAbove={[0, null, 1.5]}
        spaceBelow={[0, null, 1.5]}
        flexGrow={1}
      >
        <Visibility visible={["block", null, "none"]}>
          <PlainLink
            preset={TypePreset.Body_01}
            color={Color.Greystone_1400}
            weight={FontWeight.SemiBold}
            target="_blank"
            href={link}
            onClick={onClick}
          >
            {description}
          </PlainLink>
        </Visibility>
        <Visibility visible={["none", null, "block"]}>
          <P color={ColorPreset.TextOnLight_01} weight={FontWeight.Bold}>
            {description}
          </P>
        </Visibility>
      </Box>
      <Visibility visible={["none", null, "block"]}>
        <PlainLink
          preset={TypePreset.Body_01}
          color={Color.Greystone_1400}
          weight={FontWeight.SemiBold}
          textDecoration="underline"
          target="_blank"
          href={link}
          effect={HoverEffect.TextDecoration}
          onClick={onClick}
        >
          {linkLabel}
        </PlainLink>
      </Visibility>
      <Visibility visible={["block", null, "none"]}>
        <Box spaceBefore={2} gutterH={0.75} gutterV={0.75}>
          <Icon size="12px" name={Glyph.ChevronRight} />
        </Box>
      </Visibility>
    </Box>
  );
};
