import {
  Checkbox,
  Color,
  Dialog,
  Field,
  H4,
  Interpose,
  Label,
  Space,
  TextArea,
  TypePreset,
  P,
  Option,
  Button,
  ButtonLayout,
  ButtonVariant,
  ButtonSize,
  ButtonGroup,
  FormFieldStatus,
  Hint,
  H5,
  Select,
} from "@gocardless/flux-react";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { useForm } from "react-hook-form";
import { useToastNotification } from "src/common/notifications/useToastNotification";
import { useUser } from "src/queries/user";
import { getInputErrorStatus } from "src/utils/inputValidation";
import { getFormspreeApiUrl } from "src/utils/formspree";
import { useFeedbackFormOptions } from "src/common/hooks/useFeedbackFormOptions";

export interface FeedbackFormProps {
  toggleDialog: () => void;
  isDialogOpen: boolean;
  showAdditionalQuestions?: boolean;
}

interface FeedbackFormValuesProps {
  experience_rating: string;
  performance_rating: string;
  understanding_rating: string;
  useful_rating: string;
  message: string;
  contact: boolean;
}

export const FeedbackForm = ({
  toggleDialog,
  isDialogOpen,
  showAdditionalQuestions = false,
}: FeedbackFormProps) => {
  const user = useUser();
  const { triggerSuccessNotification, triggerErrorNotification } =
    useToastNotification();

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<FeedbackFormValuesProps>();

  const { i18n } = useLingui();

  const { experienceOptions, importanceOptions, understandingOptions } =
    useFeedbackFormOptions();

  const submitForm = async ({
    experience_rating,
    performance_rating,
    understanding_rating,
    useful_rating,
    message,
    contact,
  }: FeedbackFormValuesProps) => {
    try {
      const response = await fetch(getFormspreeApiUrl("reporting"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: user?.id || "",
          user_agent: window.navigator.userAgent,
          _subject: "Reporting Feedback",
          experience_rating,
          contact: contact ? "Yes" : "No",
          ...(performance_rating ? { performance_rating } : null),
          ...(understanding_rating ? { understanding_rating } : null),
          ...(useful_rating ? { useful_rating } : null),
          ...(message ? { message } : null),
        }),
      });
      if (response.status !== 200) throw Error("Invalid response");

      triggerSuccessNotification({
        message: i18n._(
          t({
            id: "leave-feedback.feedback-success",
            message: "Thanks for your feedback",
          })
        ),
      });
      toggleDialog();
      reset();
    } catch {
      triggerErrorNotification({
        message: i18n._(
          t({
            id: "leave-feedback.error",
            message: "There was a problem, please try again.",
          })
        ),
      });
    }
  };

  const additionalFormDropdowns = (
    <>
      <Field>
        <Label htmlFor="performance_rating">
          <H5 preset={TypePreset.Body_01}>
            <Trans>
              How important is reporting on revenue performance for your
              business?
            </Trans>
          </H5>
          <Space v={0.5} />
          <Select
            id="performance_rating"
            data-testid="reporting-feedback-performance"
            css={{
              fontSize: "14px",
            }}
            {...register("performance_rating", {
              required: false,
            })}
          >
            {importanceOptions.map((option) => (
              <Option key={option.key} value={option.value}>
                {option.key}
              </Option>
            ))}
          </Select>
        </Label>
      </Field>
      <Space v={1.5} />
      <Field>
        <Label htmlFor="useful_rating">
          <P preset={TypePreset.Body_01}>
            <Trans>
              How useful is the data in giving insights around your business?
            </Trans>
          </P>
          <Space v={0.5} />
          <Select
            id="useful_rating"
            data-testid="reporting-feedback-useful"
            css={{
              fontSize: "14px",
            }}
            {...register("useful_rating", {
              required: false,
            })}
          >
            {importanceOptions.map((option) => (
              <Option key={option.key} value={option.value}>
                {option.key}
              </Option>
            ))}
          </Select>
        </Label>
      </Field>
      <Space v={1.5} />
      <Field>
        <Label htmlFor="understanding_rating">
          <H5 preset={TypePreset.Body_01}>
            <Trans>
              How easy is it to understand the data that we are presenting?
            </Trans>
          </H5>
          <Space v={0.5} />
          <Select
            id="understanding_rating"
            data-testid="reporting-feedback-understanding"
            css={{
              fontSize: "14px",
            }}
            {...register("understanding_rating", {
              required: false,
            })}
          >
            {understandingOptions.map((option) => (
              <Option key={option.key} value={option.value}>
                {option.key}
              </Option>
            ))}
          </Select>
        </Label>
      </Field>
    </>
  );

  return (
    <Dialog
      aria-labelledby="reporting-feedback-dialog-title"
      open={isDialogOpen}
      closeAction={{
        label: <Trans id="Close">Close</Trans>,
        onClose: () => {
          toggleDialog();
          reset();
        },
      }}
      header={
        <H4
          id="reporting-feedback-dialog-title"
          data-testid="reporting-feedback-dialog-title"
          preset={TypePreset.Heading_02}
          color={Color.Greystone_DarkMatter}
        >
          <Trans>Leave feedback</Trans>
        </H4>
      }
      footer={
        <ButtonGroup arrangement={["column-center", null, "row-end-reverse"]}>
          <Button
            type="submit"
            layout={[ButtonLayout.Full, null, ButtonLayout.Inline]}
            variant={ButtonVariant.PrimaryOnLight}
            size={ButtonSize.Sm}
          >
            <Trans id="submit">Submit</Trans>
          </Button>
        </ButtonGroup>
      }
      as="form"
      onSubmit={handleSubmit(submitForm)}
    >
      <Interpose node={<Space v={1.5} />}>
        <Field>
          <Label htmlFor="experience_rating">
            <H5 preset={TypePreset.Body_01}>
              <Trans>
                How would you rate your experience with this new feature?
              </Trans>
            </H5>
            <Space v={0.5} />
            <Select
              id="experience_rating"
              data-testid="reporting-feedback-experience"
              defaultValue="Please select"
              {...register("experience_rating", {
                required: true,
              })}
              css={{
                fontSize: "14px",
              }}
              status={getInputErrorStatus(!!errors.experience_rating)}
            >
              {experienceOptions.map((option) => (
                <Option key={option.key} value={option.value}>
                  {option.key}
                </Option>
              ))}
            </Select>
            {errors.experience_rating ? (
              <>
                <Space v={0.5} />
                <Hint status={FormFieldStatus.Danger}>
                  <P preset={TypePreset.Caption_01}>
                    <Trans>
                      Required field. Please choose an option to submit.
                    </Trans>
                  </P>
                </Hint>
              </>
            ) : null}
          </Label>
        </Field>
        <Field>
          <Label htmlFor="message">
            <H5 preset={TypePreset.Body_01}>
              <Trans>Please state the reason for this score</Trans>
            </H5>
            <Space v={0.5} />
            <TextArea
              {...register("message", {
                required: false,
              })}
              css={{
                fontSize: "14px",
              }}
              id="message"
              placeholder={i18n._(t({ message: "Add description here" }))}
            />
          </Label>
        </Field>
        {showAdditionalQuestions && additionalFormDropdowns}
        <Field>
          <Checkbox
            {...register("contact", {
              required: false,
            })}
            defaultChecked={false}
            data-testid="reporting-feedback-checkbox"
          >
            <H5 preset={TypePreset.Body_01}>
              <Trans>I am willing to be contacted</Trans>
            </H5>
            <P
              preset={TypePreset.Caption_01}
              color={Color.Greystone_1200}
              weight={400}
            >
              <Trans>
                Please tick this option if you are happy for a member of the
                product team to reach out to speak about your experience
              </Trans>
            </P>
          </Checkbox>
        </Field>
      </Interpose>
    </Dialog>
  );
};
