import Image from "next/image";
import {
  AlignItems,
  Box,
  ButtonSize,
  ButtonVariant,
  Color,
  ColorPreset,
  FontWeight,
  Glyph,
  H2,
  IconButton,
  JustifyContent,
  P,
  PlainButton,
  ReadingOptimized,
  Space,
  Text,
  TypeScale,
  Visibility,
  useTheme,
} from "@gocardless/flux-react";
/* eslint-disable-next-line import/no-unresolved */
import FeedbackAnimatedIcon from "src/assets/svg/feedback-animated.svg";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";

import { betaWidgetStyle } from "./BetaWidget.style";
interface BetaWidgetProps {
  onButtonClick: () => void;
}

const BetaWidget: React.FC<BetaWidgetProps> = ({ onButtonClick }) => {
  const { i18n } = useLingui();
  const { theme } = useTheme();

  const Heading = () => (
    <H2
      color={Color.Ultraviolet_700}
      decoration={["underline", "none"]}
      layout={["inline", "block"]}
      size={[TypeScale.Size_02, TypeScale.Size_03]}
      spaceBelow={[0, 0.5]}
      weight={FontWeight.SemiBold}
    >
      {i18n._(t({ message: "Share your feedback" }))}
    </H2>
  );

  return (
    <Box
      bg={[Color.Ultraviolet_50, Color.White]}
      borderColor={[Color.Transparent, ColorPreset.BorderOnLight_04]}
      borderWidth={[0, 1]}
      borderRadius={1}
      flexGrow={1}
      gutterH={1.5}
      gutterV={[1, 1.5]}
      justifyContent={JustifyContent.SpaceBetween}
      css={betaWidgetStyle(theme)}
    >
      <Box
        layout="flex"
        flexDirection={["row-reverse", "column"]}
        justifyContent={JustifyContent.Center}
        alignItems={[AlignItems.Center, AlignItems.FlexStart]}
      >
        <Text spaceBelow={[0, 0.75]}>
          <Image src={FeedbackAnimatedIcon} alt="" width={16} height={16} />
        </Text>
        <Space h={[0.5, 0]} layout="inline" />

        <Visibility visible={["block", "none"]}>
          <PlainButton onClick={onButtonClick}>
            <Heading />
          </PlainButton>
        </Visibility>

        <Visibility visible={["none", "block"]}>
          <Heading />
        </Visibility>

        <Visibility visible={["none", "block"]}>
          <ReadingOptimized textWidth={["100%"]}>
            <P size={TypeScale.Size_01}>
              <Trans>
                Answer a few questions and tell us about your experience using
                the new reporting feature.
              </Trans>
            </P>
          </ReadingOptimized>
        </Visibility>
      </Box>
      <Visibility visible={["none", "block"]}>
        <IconButton
          icon={Glyph.ArrowForward}
          label={i18n._(t({ message: "Open feedback form" }))}
          onClick={onButtonClick}
          size={ButtonSize.Sm}
          variant={ButtonVariant.SecondaryOnLight}
        />
      </Visibility>
    </Box>
  );
};

export default BetaWidget;
