import { useLanguageSettings } from "src/components/routes/Settings/language/useLanguageSettings";
import { LanguageCode } from "src/common/i18n";

const DEFAULT_LANGUAGE = LanguageCode.EN;

export const useNeedHelpCardUrls = () => {
  const { userDetails } = useLanguageSettings();
  const userLanguage = userDetails?.users?.language || DEFAULT_LANGUAGE;

  const meetingURL = {
    [LanguageCode.EN]:
      "https://gocardless.chilipiper.com/book/standard-success",
    [LanguageCode.DE]:
      "https://gocardless.chilipiper.com/book/dach-self-serve-scs-a-a",
    [LanguageCode.ES]:
      "https://gocardless.chilipiper.com/book/esp-self-serve-scs-a-a",
    [LanguageCode.FR]:
      "https://gocardless.chilipiper.com/book/fr-self-serve-scs-a-a",
  }[userLanguage];

  return {
    meetingURL,
  };
};
