import {
  CreditorBalanceResource,
  Currency,
} from "@gocardless/api/dashboard/types";
import {
  AlignItems,
  JustifyContent,
  Box,
  ButtonGutter,
  ButtonSize,
  ButtonVariant,
  Color,
  ColorPreset,
  FontWeight,
  Glyph,
  H3,
  IconButton,
  MoneyText,
  Space,
  Tooltip,
  TypePreset,
  TypeScale,
} from "@gocardless/flux-react";
import { useI18n } from "src/common/i18n";
import { round } from "lodash";
import { creditorBalanceAmount } from "src/components/routes/Home/components/utils";
import { FundCategoryType } from "src/components/routes/Home/components/constants";
import { PayoutTimingsText } from "src/components/routes/Home/components/PayoutTimingsText";

interface CarouselConfigItem {
  title: string;
  type: FundCategoryType;
  tooltipCopy: React.ReactNode;
  color: Color;
}

export const CreditorBalanceCard: React.FC<{
  card: CarouselConfigItem;
  creditorBalance: CreditorBalanceResource;
}> = ({ card, creditorBalance }) => {
  const [locale] = useI18n();

  const amount = round(
    creditorBalanceAmount(creditorBalance, card.type) / 100,
    2
  );
  const contentColor =
    card.type === FundCategoryType.PAYOUT && amount === 0
      ? ColorPreset.TextOnLight_03
      : card.type === FundCategoryType.COLLECTED && amount < 0
        ? ColorPreset.AlertTextOnLight
        : ColorPreset.TextOnLight_01;

  const titleText = (): React.ReactNode => {
    if (card.type === FundCategoryType.PAYOUT) {
      return (
        <PayoutTimingsText
          amount={amount}
          payoutDate={creditorBalance.closest_payout_date as unknown as string}
          payoutId={creditorBalance.closest_payout_id as string | undefined}
        />
      );
    } else {
      return card.title;
    }
  };

  return (
    <Box
      layout="flex"
      flexDirection="column"
      width="100%"
      justifyContent={JustifyContent.FlexStart}
    >
      <Box layout="flex" alignItems={AlignItems.Center}>
        <H3
          preset={TypePreset.Subheading_01}
          size={TypeScale.Size_04}
          color={ColorPreset.TextOnLight_01}
          weight={FontWeight.Light}
        >
          {titleText()}
        </H3>
        <Space layout="inline" h={0.5} />
        <Tooltip message={card.tooltipCopy} triggeredBy="click">
          {(triggerProps) => (
            <IconButton
              {...triggerProps}
              size={{ base: ButtonSize.Md, gutters: ButtonGutter.Sm }}
              icon={Glyph.Tooltip}
              label={`See more information about ${titleText()}`}
              variant={ButtonVariant.TextOnLight}
            />
          )}
        </Tooltip>
      </Box>
      <Space v={0.5} />
      <MoneyText
        data-testid={`amount-${card.type}`}
        amount={amount}
        currency={creditorBalance.currency as Currency}
        locale={locale}
        preset={TypePreset.Heading_07}
        color={contentColor}
      />
    </Box>
  );
};
