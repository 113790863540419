import { useId } from "react";
import {
  Currency,
  OrganisationResource,
} from "@gocardless/api/dashboard/types";
import {
  AlignItems,
  Box,
  ColorPreset,
  Dropdown,
  FieldSet,
  FontWeight,
  Glyph,
  Icon,
  Legend,
  Menu,
  MenuRole,
  Radio,
  Separator,
  Space,
  Text,
  ToggleControlPosition,
  TypePreset,
  TypeScale,
  useTheme,
  PlainButton,
  HoverEffect,
} from "@gocardless/flux-react";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { currencyToCountryIconName } from "src/common/currencies";

import { useMenuStyle } from "../../utils/style";
import { useReportingContext } from "../ReportingContextProvider";

export interface CollectionCurrencyMenuProps {
  currencies: Currency[];
  fxPayoutCurrency: OrganisationResource["fx_payout_currency"];
}

const CollectionCurrencyMenu: React.FC<CollectionCurrencyMenuProps> = ({
  currencies,
  fxPayoutCurrency,
}) => {
  const handleChangeCurrency: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setSelectedCurrency(event.target.value as Currency);
  };

  const {
    isChartWidgetLoading,
    areDataWidgetsLoading,
    selectedCurrency,
    setSelectedCurrency,
  } = useReportingContext();

  const menuStyle = useMenuStyle();
  const { i18n } = useLingui();
  const { theme } = useTheme();

  const dropdownId = useId();

  return (
    <Box layout="flex" alignItems={AlignItems.Center}>
      <Dropdown
        controls={dropdownId}
        trigger={(triggerProps) => (
          <PlainButton
            {...triggerProps}
            aria-label={i18n._(t({ message: "Select collection currency" }))}
            disabled={isChartWidgetLoading || areDataWidgetsLoading}
            effect={HoverEffect.TextDecoration}
          >
            <Icon
              name={currencyToCountryIconName[selectedCurrency]}
              verticalAlign="middle"
            />
            <Space layout="inline" h={0.5} />
            <Text
              data-testid="selected-collection-currency"
              preset={TypePreset.Body_01}
              weight={FontWeight.SemiBold}
            >
              {selectedCurrency}
            </Text>
            <Space layout="inline" h={0.5} />
            <Icon name={Glyph.ChevronDown} size="12px" />
          </PlainButton>
        )}
      >
        {({ close }) => (
          <Menu.Root id={dropdownId} role={MenuRole.Menu}>
            <FieldSet
              css={{
                "[data-flux-role]": { marginBottom: theme.spacing(1) },
              }}
            >
              <Box layout="flex" gutterH={1} gutterV={0.5}>
                <Legend>
                  <Text size={TypeScale.Size_03} weight={FontWeight.SemiBold}>
                    <Trans>Choose collection currency</Trans>
                  </Text>
                </Legend>
              </Box>
              <Separator spacing={1} color={ColorPreset.BorderOnLight_05} />
              <Box
                css={{
                  ...menuStyle,
                  "[data-field-control] > div": {
                    alignItems: AlignItems.FlexStart,
                  },
                }}
                layout="flex"
                flexDirection="column"
              >
                {currencies.map((currency) => {
                  const iconName = currencyToCountryIconName[currency];

                  const showFXCurrency =
                    !!fxPayoutCurrency && fxPayoutCurrency !== currency;

                  return (
                    <Radio
                      key={currency}
                      checked={selectedCurrency === currency}
                      controlPosition={ToggleControlPosition.End}
                      description={
                        <Text
                          color={ColorPreset.TextOnLight_03}
                          preset={TypePreset.Body_01}
                          weight={FontWeight.Normal}
                        >
                          {
                            <Trans>
                              Paid out in{" "}
                              {showFXCurrency ? fxPayoutCurrency : currency}
                            </Trans>
                          }
                        </Text>
                      }
                      name="collectionCurrency"
                      onChange={(event) => {
                        handleChangeCurrency(event);
                        close();
                      }}
                      value={currency}
                    >
                      <Box
                        layout="flex"
                        alignItems={AlignItems.Center}
                        spaceBelow={0.25}
                      >
                        {iconName && (
                          <>
                            <Icon
                              css={{ borderRadius: "100%" }}
                              name={iconName}
                              verticalAlign="middle"
                            />
                            <Space h={0.5} layout="inline" />
                          </>
                        )}
                        {currency}
                      </Box>
                    </Radio>
                  );
                })}
              </Box>
            </FieldSet>
          </Menu.Root>
        )}
      </Dropdown>
    </Box>
  );
};

export default CollectionCurrencyMenu;
