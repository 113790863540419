import {
  Button,
  ButtonGroup,
  ButtonLayout,
  ButtonVariant,
  FontWeight,
  PlainButton,
  Text,
  TextAlign,
  TypePreset,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { FC } from "react";
import { Route, routerPush } from "src/common/routing";
import { TrackingEvent } from "src/common/trackingEvents";
import { OptimizelyFlag } from "src/components/third-parties/Optimizely/constants";
import { useOptimizelyVariation } from "src/components/third-parties/Optimizely/useOptimizelyVariation";
import { useSegment } from "src/components/third-parties/Segment/useSegment";

interface BackProps {
  onBackClick: () => void;
}

interface CreatePaymentProps extends BackProps {
  onCreatePaymentClick: () => void;
}

export const BackButton: FC<BackProps> = ({ onBackClick }) => (
  <ButtonGroup arrangement={["column-center", null, null, "row-end"]}>
    <Button
      variant={ButtonVariant.PrimaryOnLight}
      onClick={onBackClick}
      layout={[ButtonLayout.Full, null, null, ButtonLayout.Inline]}
    >
      <Trans id="Back">Back</Trans>
    </Button>
  </ButtonGroup>
);

export const CreatePaymentWithBackButton: FC<CreatePaymentProps> = ({
  onCreatePaymentClick,
  onBackClick,
}) => {
  const { sendEvent } = useSegment();

  return (
    <ButtonGroup
      arrangement={["column-center-reverse", null, null, "row-end"]}
      spacing={2}
    >
      <PlainButton
        weight={FontWeight.SemiBold}
        preset={TypePreset.Body_01}
        onClick={onBackClick}
      >
        <Trans id="Back">Back</Trans>
      </PlainButton>
      <Button
        variant={ButtonVariant.PrimaryOnLight}
        onClick={() => {
          sendEvent(TrackingEvent.GET_STARTED_PAYOUT_DIALOG_CTA_CLICKED, {
            target: "create_payment ",
          });
          onCreatePaymentClick();
        }}
        layout={[ButtonLayout.Full, null, null, ButtonLayout.Inline]}
      >
        <Trans id="get-your-first-payout.create-payment-button">
          Create payment
        </Trans>
      </Button>
    </ButtonGroup>
  );
};

export const ViewPaymentWithBackButton: FC<CreatePaymentProps> = ({
  onCreatePaymentClick,
  onBackClick,
}) => {
  const { sendEvent } = useSegment();
  const { isVariationOn } = useOptimizelyVariation({
    flag: OptimizelyFlag.TURBO_GROWTH_KEEP_COLLECTING_CONFIDENTLY,
  });

  if (isVariationOn) {
    return (
      <ButtonGroup
        arrangement={["column-center-reverse", null, null, "row-end"]}
        spacing={2}
      >
        <PlainButton
          onClick={() => {
            sendEvent(TrackingEvent.GET_STARTED_PAYOUT_DIALOG_CTA_CLICKED, {
              target: "view_payments",
            });
            routerPush({ route: Route.Payments });
          }}
        >
          <Text weight={FontWeight.SemiBold} textAlign={TextAlign.Center}>
            <Trans id="get-your-first-payout.view-scheduled-payments-button">
              View scheduled payments
            </Trans>
          </Text>
        </PlainButton>
        <Button
          variant={ButtonVariant.PrimaryOnLight}
          onClick={() => {
            sendEvent(TrackingEvent.GET_STARTED_PAYOUT_DIALOG_CTA_CLICKED, {
              target: "create_payment",
            });
            onCreatePaymentClick();
          }}
          layout={[ButtonLayout.Full, null, null, ButtonLayout.Inline]}
        >
          <Trans id="get-your-first-payout.create-payment-button">
            Create payment
          </Trans>
        </Button>
      </ButtonGroup>
    );
  }

  return (
    <ButtonGroup
      arrangement={["column-center-reverse", null, null, "row-end"]}
      spacing={2}
    >
      <PlainButton
        weight={FontWeight.SemiBold}
        preset={TypePreset.Body_01}
        onClick={onBackClick}
      >
        <Trans id="Back">Back</Trans>
      </PlainButton>
      <Button
        variant={ButtonVariant.PrimaryOnLight}
        onClick={() => {
          sendEvent(TrackingEvent.GET_STARTED_PAYOUT_DIALOG_CTA_CLICKED, {
            target: "view_payments",
          });
          routerPush({ route: Route.Payments });
        }}
        layout={[ButtonLayout.Full, null, null, ButtonLayout.Inline]}
      >
        <Trans id="get-your-first-payout.view-payments-button">
          View payments
        </Trans>
      </Button>
    </ButtonGroup>
  );
};
